<template>
  <div>
    <form novalidate class="login-form md-layout">
      <md-card
        class="md-layout-item md-large-size-40 md-size-50 md-small-size-60 md-xsmall-size-100 transparent-background"
      >
        <md-card-header>
          <a href="#/">
            <v-img :src="logoImg" max-width="250px" class="item-center" />
          </a>
          <div class="md-title">Not Found</div>
        </md-card-header>

        <md-card-content>
          Sorry, this page was not found.
        </md-card-content>

        <md-card-actions>
          <md-button id="home" class="md-raised md-primary" to="/app"
            >Go to home</md-button
          >
        </md-card-actions>
      </md-card>
    </form>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  data: () => ({
    logoImg: require(`../assets/seamless-source-banner.png`),
  }),
};
</script>

<style lang="scss">
.login-form {
  padding-top: 100px;
  justify-content: center;
}
.item-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.transparent-background {
  background: rgba(255, 255, 255, 0.9) !important;
}
</style>
