<template>
  <v-app>
    <v-container fluid tag="section">
      <div v-if="!notFoundBom" id="Bom" class="public-bom-container">
        <bom-component @invalid-style="markBomAsNotFound" />
        <div class="public-activity-log-container">
          <bom-log @invalid-style="markBomAsNotFound" />
        </div>
      </div>
      <div v-else style="height: 100%; width: 100%">
        <NotFound />
      </div>
    </v-container>
  </v-app>
</template>

<script>
import BomLog from "../../components/Inquiry/BomLog.vue";
import BomComponent from "../../components/Inquiry/BomComponent.vue";
import NotFound from "../NotFound.vue";

export default {
  name: "PublicBom",
  components: {
    BomComponent,
    BomLog,
    NotFound,
  },
  data: () => ({
    notFoundBom: false,
  }),
  methods: {
    markBomAsNotFound() {
      this.notFoundBom = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.public-bom-container {
  background-color: #fff;
  padding-top: 10px;
  margin: 8px 40px;
  min-height: 50vh;
}

.public-activity-log-container {
  margin-top: 15px;
  background-color: #eef1fa;
  padding: 10px;
  width: 100%;
  box-shadow: unset;
}
</style>
